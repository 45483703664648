<template>
  <v-app :style="`background-color: ${$vuetify.theme.dark ? '#1e1e1e' : '#F7F9FB'};`">
    <app-bar />
    <v-main>
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>
    <ag-notify />
    <update-me-container />
    <switch-user-container />
    <check-user-container />
  </v-app>
</template>

<script>
import AppBar from 'src/components/layout/AppBar'
import AgNotify from 'src/components/common/AgNotify'
import UpdateMeContainer from '../modules/general/containers/UpdateMeContainer'
import SwitchUserContainer from '../modules/general/containers/SwitchUserContainer'
import CheckUserContainer from '../modules/general/containers/CheckUserContainer'

export default {
  name: 'DefaultLayout',
  components: {
    AppBar,
    AgNotify,
    UpdateMeContainer,
    SwitchUserContainer,
    CheckUserContainer,
  },
}
</script>
