<template>
  <v-app-bar
    color="lightbg"
    app
    clipped-right
    style="z-index: 6"
  >
    <v-switch
      v-model="darkMode"
      hide-details
      inset
      color="accent"
    />
    <v-spacer />

    <v-toolbar-title v-text="'Flowmem Atelier'" />

    <v-spacer />

    <current-user-button-menu />
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CurrentUserButtonMenu from './CurrentUserButtonMenu'

export default {
  name: 'AppBar',
  components: {
    CurrentUserButtonMenu,
  },
  computed: {
    ...mapGetters('ui', {
      isDarkMode: 'isDarkMode',
    }),
    darkMode: {
      get () {
        return this.isDarkMode
      },
      set (val) {
        this.toggleDark(val)
      },
    },
  },
  methods: {
    ...mapActions('ui', {
      toggleDark: 'toggleDark',
    }),
  },
}
</script>
